import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { GlobalHandlers } from './GlobalHandlers';
import './scss/index.scss';
import { store } from './store';
import { APP_VERSION } from 'src/helpers/new/AppConfig';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';

const isGuardianBrowser = navigator.userAgent.includes('Guardian');
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 1.0,
    release: APP_VERSION,
    beforeSend(event) {
        if (isGuardianBrowser) {
            if (event?.exception?.values?.length > 0) {
                const { value: errorMessage, type: errorType } = event.exception.values[0];

                if (
                    errorMessage?.includes("Identifier 'originalOpen' has already been declared") &&
                    errorType === 'SyntaxError'
                ) {
                    return null;
                }
            }
        }
        return event;
    },
});

global.USCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

Number.prototype.cleanRound = function (dp) {
    return Math.round((this + Number.EPSILON) * 10 ** dp) / 10 ** dp;
};

// this is the actual start
createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <GlobalHandlers />
        <App />
    </Provider>,
);
