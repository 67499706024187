import './scss/App.scss';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainLayout from './layouts/Main/Main';
import { Login, ResetPassword, ChangePassword, Profile, VerifyAccount, ApplicationStatus } from './pages/Account';
import { Home, Course } from './pages';
import { Notifications } from './pages/Notifications';
import { Policy } from './pages/Policy';
import { NotFound } from './pages/NotFound';
import Checkout from './pages/Checkout';
import Stripe from './pages/Checkout/StepCheckout/Tabs/Payment/Stripe/Stripe';
import Confirmation from './pages/Checkout/StepCheckout/Tabs/Payment/Confirmation/Confirmation';
import PublicProfileNotifications from './pages/Account/PublicProfileNotifications/PublicProfileNotifications';
import PreviewCourse from 'src/pages/Admin/Courses/Lesson/PreviewCourse';
import { Spinner } from './components/Spinner';
import ErrorBoundary from './GlobalHandlers/ErrorBoundry';
import CookieConfig from './layouts/Main/CookieConfig/CookieConfig';
import { Utility } from './helpers/new';
import { ErrorPageStandalone, ErrorPageWithRouter } from './components/CustomError/index';

const Admin = lazy(() => import('./pages/Admin/Admin.jsx'));

const App = () => {
    const [isInIframe, setIsInIframe] = useState(false);

    useEffect(() => {
        if (window.self !== window.top && !isInIframe) {
            setIsInIframe(true);
        }

        return () => {
            setIsInIframe(false);
        };
    }, []);

    const getUsersPermissions = (name) => Utility.getUsersPermissions(name);

    if (!navigator.cookieEnabled) {
        return <CookieConfig />;
    }

    if (isInIframe) {
        return <ErrorPageStandalone className='iframe-wrapper' isIframe={true} />;
    }

    const isElectronApp = () => {
        return navigator.userAgent.includes('GuardianBrowser')
            ? false
            : navigator.userAgent.includes('Electron') || !!window?.electron;
    };

    if (isElectronApp()) {
        return <ErrorPageStandalone isIframe={false} />;
    }

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <ErrorBoundary>
                    <Suspense fallback={<Spinner />}>
                        <MainLayout />
                    </Suspense>
                </ErrorBoundary>
            ),
            children: [
                { path: '/', element: <Home /> },
                { path: 'extension/:userPackageId', element: <Home /> },
                { path: 'courses/:courseId/*', element: <Course /> },
                { path: 'login', element: <Login /> },
                { path: 'status', element: <ApplicationStatus /> },
                { path: 'profile/*', element: <Profile /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'change-password', element: <ChangePassword /> },
                { path: 'verify-account', element: <VerifyAccount /> },
                { path: 'notifications', element: <Notifications /> },
                { path: 'checkout', element: <Checkout /> },
                { path: 'checkout/payment/stripe/:stripePaymentMethod', element: <Stripe /> },
                { path: 'checkout/payment/confirmation/:orderId', element: <Confirmation /> },
                { path: 'terms_of_service', element: <Policy keyword='terms_of_service' title='Terms Of Service' /> },
                { path: 'privacy_policy', element: <Policy keyword='privacy_policy' title='Privacy Policy' /> },
                { path: 'unsubscribe-notifications', element: <PublicProfileNotifications /> },
                { path: 'custom_error_page', element: <ErrorPageWithRouter /> },
                { path: '*', element: <NotFound /> },
                getUsersPermissions('courses')?.includes('viewCourses') && {
                    path: 'preview/:courseId/chapters/:chapterId/lessons/:lessonId',
                    element: <PreviewCourse />,
                },
                { path: 'admin/*', element: <Admin /> },
            ].filter(Boolean),
        },
    ]);

    return <RouterProvider router={router} />;
};

export default App;
